// Import Alpine.js
import Alpine from 'alpinejs';
import flatpickr from 'flatpickr';
import toastr from 'toastr';


window.Alpine = Alpine;
Alpine.start();
window.Toastr = toastr;
document.addEventListener('DOMContentLoaded', () => {
  flatpickr('.datepicker', {
    mode: 'range',
    static: true,
    position: 'center',
    monthSelectorType: 'static',
    dateFormat: 'M j, Y',
    defaultDate: [new Date().setDate(new Date().getDate() - 6), new Date()],
    prevArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
    nextArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    onReady: (selectedDates, dateStr, instance) => {
      // eslint-disable-next-line no-param-reassign
      instance.element.value = dateStr.replace('to', '-');
      const customClass = instance.element.getAttribute('data-class');
      instance.calendarContainer.classList.add(customClass);
    },
    onChange: (selectedDates, dateStr, instance) => {
      // eslint-disable-next-line no-param-reassign
      instance.element.value = dateStr.replace('to', '-');
    },
  });
});

$(document).ready(function () {

	$("select.ui-timepicker-select").select2({
		width: '100%',
		placeholder: "Arrival Time",
		minimumResultsForSearch: -1,
		maximumSelectionLength: 3
	});

	$('.js_slide_button').on('click', function () {
		if ($(window).width() < 992) {
			$('.js_logo_block').css({'flex': '1 1 37%'});

			setTimeout(function () {
				$('.js_logo').addClass('!w-230px');
				$('.js_cta_block').css({'flex': '1 1 70%'});
			}, 300);
		}

		$(".js_first_slide").css({
			'transform': 'translateX(-100%)',
			'position': 'absolute'
		}).fadeOut(500);

		$(".js_second_slide").css({
			'transform': 'translateX(0)',
			'opacity': '1',
			'position': 'initial'
		})
	});

	$('.js_back_btn').on('click', function () {
		window.history.back();
	});

	if ($('div').hasClass('product_gallery')) {
		const gallery = new Carousel(document.querySelector(".product_gallery"), {
			slidesPerPage: '1',
			center: false,
			Dots: true,
			fill: false,
			infinite: false,
			Navigation: {
				prevTpl: '<svg class="max-tablet:w-[33px] max-tablet:h-[33px]" width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">' +
					'<path d="M27.4999 50.4167C40.1564 50.4167 50.4166 40.1565 50.4166 27.5C50.4166 14.8435 40.1564 4.58333 27.4999 4.58333C14.8434 4.58333 4.58325 14.8435 4.58325 27.5C4.58325 40.1565 14.8434 50.4167 27.4999 50.4167Z" fill="white" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>' +
					'<path d="M27.4999 18.3333L18.3333 27.5L27.4999 36.6667" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>' +
					'<path d="M36.6666 27.5H18.3333" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>' +
					'</svg>',
				nextTpl: '<svg class="max-tablet:w-[33px] max-tablet:h-[33px]" width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">' +
					'<path d="M27.4998 4.58331C14.8433 4.58332 4.58316 14.8435 4.58316 27.5C4.58316 40.1565 14.8433 50.4167 27.4998 50.4167C40.1564 50.4167 50.4165 40.1565 50.4165 27.5C50.4165 14.8435 40.1564 4.58331 27.4998 4.58331Z" fill="white" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>' +
					'<path d="M27.4998 36.6667L36.6665 27.5L27.4998 18.3333" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>' +
					'<path d="M18.3332 27.5L36.6665 27.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>' +
					'</svg>',
			},
			classNames: {
				viewport: "product_viewport",
				track: "product_track",
				slide: "product_holder",
				slideSelected: "is-selected",
			},
			on: {
				ready: (carousel) => {
					$('.carousel__dot').removeAttr('role');
					$('.carousel__button').addClass('absolute top-1/2 -translate-y-1/2');
					$('.carousel__button.is-next').addClass('right-2.5');
					$('.carousel__button.is-prev').addClass('left-2.5');
					$('.carousel__dots').addClass('flex gap-1.5 absolute left-1/2 -translate-x-1/2 bottom-5');
					$('.carousel__dot').addClass('h-[8px] w-[8px] rounded-full bg-white opacity-50');
					$('.carousel__dot.is-selected').removeClass('opacity-50');
				},
				selectSlide: (carousel) => {
					$('.carousel__dot').addClass('opacity-50');
					$('.carousel__dot.is-selected').removeClass('opacity-50');
				},
			},
		});
	}

    if(window.innerWidth > 100000) {
        $("select.select").select2({
            width: '100%',
            minimumResultsForSearch: -1
        });
    } else {
        $("select.select").removeClass('select').addClass('p-18px bg-transparent w-full focus:outline-0 border border-gray-input-border rounded-7px leading-5 text-lg text-black placeholder:text-lg placeholder:text-gray-input-text');
    }

	$('.js_accordion_title').on('click', function () {
		var item = $(this).next('.js_accordion_content');

		$('.js_accordion_content').not(item).slideUp();
		item.slideToggle();
		$('.js_accordion_title').not(this).find('button svg').removeClass('rotate-180');
		$(this).find('button svg').toggleClass('rotate-180')
	});

	var $grid = $('.js_grid').masonry({
		// set itemSelector so .grid-sizer is not used in layout
		itemSelector: '.js_grid_item',
		// use element for option
		columnWidth: '.js_grid_item',
		percentPosition: true,
		horizontalOrder: true
	})

	$('.js_added_button').on('click', function () {
		var name_product = $(this).parents('.js_product_card').find('.js_name_product').text();
		$(this).parents('.js_product_card').find('.js_added_text').addClass('!flex');
		$grid.masonry();
		$('.js_cart_list').append('<li class="js_cart_item">' +
			'<p class="text-lg leading-tight">' +
			'(1) ' + name_product + ' Added' +
			'</p>' +
			'<button class="js_delete_item shrink-0">' +
			'<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
			'<path d="M3 6H5H21" stroke="#CA2C2C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>' +
			'<path d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6" stroke="#CA2C2C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>' +
			'<path d="M10 11V17" stroke="#CA2C2C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>' +
			'<path d="M14 11V17" stroke="#CA2C2C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>' +
			'</svg>' +
			'</button>' +
			'</li>');
		var num_items = $('.js_cart_item').length;
		$('.js_cart_count').text(num_items);
	});

	$('.js_cart_list').on('click', '.js_delete_item', function () {
		$(this).parents('.js_cart_item').remove();
	})

	$('.js_payment_radio').on('change', function () {
		$('.js_payment_block').slideUp();
		if ($(this) + ':checked') {
			$(this).siblings('.js_payment_block').slideDown();
		}
	});
});
